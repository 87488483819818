/* eslint-disable @typescript-eslint/no-use-before-define */
import type { Story } from '@prezly/sdk';
import {
    getHomepageServerSideProps,
    type HomePageProps,
    NextContentDelivery,
} from '@prezly/theme-kit-nextjs/server';
import dynamic from 'next/dynamic';
import type { GetServerSidePropsContext } from 'next/types';
import type { FunctionComponent } from 'react';

import { FEATURED_CATEGORY_ID } from '@/constants';
import { importMessages, isTrackingEnabled } from '@/utils';
import type { BasePageProps, StoryWithImage } from 'types';

const Stories = dynamic(() => import('@/modules/Stories'), { ssr: true });

type Props<T extends keyof Story.ExtraFields = 'thumbnail_image'> = {
    featuredStories: (Story & Pick<Story.ExtraFields, T>)[];
} & BasePageProps &
    HomePageProps<T>;

const IndexPage: FunctionComponent<Props> = ({ stories, featuredStories, pagination }) => (
    <Stories stories={stories} pagination={pagination} featuredStories={featuredStories} />
);

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const prezly = NextContentDelivery.initClient(context.req);
    const { stories: featuredStories } = await prezly.stories(
        {
            limit: 12,
            query: { [`category.id`]: { $any: [FEATURED_CATEGORY_ID] } },
        },
        {
            include: ['thumbnail_image'],
        },
    );

    const result = await props(featuredStories)(context);

    return {
        ...result,
        props: {
            // @ts-expect-error -- `props` definitely exist, but narrowing doesn't work here.
            ...result.props,
            featuredStories,
        },
    };
};

export default IndexPage;

function props(featuredStories: StoryWithImage[]) {
    return getHomepageServerSideProps<BasePageProps, 'thumbnail_image'>(
        async (context, { newsroomContextProps }) => ({
            isTrackingEnabled: isTrackingEnabled(context),
            translations: await importMessages(newsroomContextProps.localeCode),
        }),
        {
            extraStoryFields: ['thumbnail_image'],
            pageSize: 12 - featuredStories.length,
            filterQuery: {
                id: {
                    $nin: featuredStories.map((s) => s.id),
                },
            },
        },
    );
}
